const LocationIcon = "/assets/tepual/general/LocationIcon.svg";
const PhoneIcon = "/assets/tepual/general/PhoneIcon.svg";
const HeaderLogo = "/assets/tepual/general/Header-BusesTepualLogo.svg";
const FooterLogo = "/assets/tepual/general/Footer-BusesTepualLogo.svg";
const ContactBg = "/assets/tepual/home/home-background.jpg";
// const PopupIcon = '/assets/tepual/gallery/gallery5.jpg';
const BookingActive = "/assets/tepual/home/BuyTicketIcon-Active.svg";
const BookingInActive = "/assets/tepual/home/BuyTicketIcon-Inactive.svg";
const CancelActive = "/assets/tepual/home/CancelTicketIcon-Active.svg";
const CancelInActive = "/assets/tepual/home/CancelTicketIcon-Inactive.svg";
const OriginIcon = "/assets/jeldres/home/origin.svg";
const DestinationIcon = "/assets/jeldres/home/dest.png";
const DestinationIcon2 = "/assets/tepual/home/SearchBar-DestinationIcon.svg";
const CalendarIcon = "/assets/tepual/home/SearchBar-CalendarIcon.svg";
const calendarIcon = "assets/jeldres/home/calender.svg";
const SearchIcon = "/assets/tepual/home/SearchBar-SearchIcon.svg";
const checkboxIcon = "/assets/tepual/checkbox.png";
const BottomFooterKuposLogo = "/assets/tepual/Footer-kuposLogo.svg";
const animation =
	"/assets/animation-screen/Services-kupos-PasajeBus-Orange.gif";

const BG = "/assets/jeldres/home-banners/home-banner.webp";
const pnr = "/assets/jeldres/find-my-pnr.png";

const contactMail = "/assets/jeldres/contact/contact-mail.svg";
const contactHeadphone = "/assets/jeldres/contact/contact-phone.svg";
const aboutUsImage = "/assets/jeldres/about-us/about-image.webp";

const operatorPay = '/assets/jeldres/home/pay.png'
const footerMail = "/assets/jeldres/footer/footer-mail.svg";
const footerPhone = "/assets/jeldres/footer/footer-phone.svg";
// banners
const destinationBanner = "/assets/jeldres/destinations/destination-main.webp";
const galleryBanner = "/assets/tepual/gallery/gallery-main.jpg";
const contactUsBanner = "/assets/jeldres/contact/contact-us-banner.webp";
const aboutUsBanner =
	"/assets/jeldres/about-us/about-us-banner.webp";
const tncBanner = "/assets/jeldres/tnc/tnc-banner.webp";
const officeBanner = "/assets/jeldres/offices/offices-banner.webp";
// const operatorPay = "/assets/santamaria/SMPaypng.png"
const OfficePhoneIcon = "/assets/tepual/general/officePhone.svg";

// accounts section
const pay = "/assets/blue/Andipay.png";
const backimage = "/assets/blue/Andimar-BackgroundMobile.png";

const tarjetas = "/assets/orange/MyAccount-IllustratedIcons-MyCards-Orange.svg";
const viajes = "/assets/orange/MyAccount-IllustratedIcons-MyTrips-Orange.svg";
const movimientos = "../../../assets/carpool/pigi-bank-circle.png";
const wallet =
	"/assets/orange/MyAccount-IllustratedIcons-RechargeWallet-Orange.svg";
const shareMoney =
	"/assets/orange/MyAccount-IllustratedIcons-ShareMoney-Orange.svg";
const camera = "/assets/carpool/camera-add-circle.png";
const checkmark = "/assets/orange/IllustratedIcons-CheckMark-Blue.png";
const tick = "/assets/orange/IllustratedIcons-Tick-Blue.png";
const downArrow = "/assets/orange/IllustratedIcons-DropDown-Blue.png";
const loginblue = "../assets/carpool/login-circle.png";
const recoverpswd = "../../assets/carpool/forgot-circle.png";
// const success = '/assets/orange/MyAccount-IllustratedIcons-CheckMark-Orange.svg';
const success =
	"/assets/expressonorte/Green/MyAccount-IllustratedIcons-CheckMark-Green.svg";
const failure =
	"/assets/expressonorte/Green/MyAccount-IllustratedIcons-Information-Green.svg";
const warning =
	"/assets/orange/MyAccount-IllustratedIcons-Information-Orange.svg";
const userIcon = "../assets/carpool/user-circle.png";
const heartOn = "../assets/icons/heart.png";
const heartOff = "../assets/icons/heart-empty.png";
const registrationSuccess = "/assets/carpool/registration-done-circle.png";

const jeldresIcons = [
	{ icon: PhoneIcon, name: "phone" },
	{ icon: LocationIcon, name: "location" },
	{ icon: HeaderLogo, name: "headerLogo" },
	{ icon: FooterLogo, name: "footerLogo" },
	{ icon: ContactBg, name: "ContactBg" },
	{ icon: BookingInActive, name: "BIA" },
	{ icon: BookingActive, name: "BA" },
	{ icon: CancelInActive, name: "CIA" },
	{ icon: CancelActive, name: "CA" },
	{ icon: OriginIcon, name: "Origin" },
	{ icon: DestinationIcon, name: "Destination" },
	{ icon: DestinationIcon2, name: "Destination2" },
	{ icon: CalendarIcon, name: "Calendar" },
	{ icon: calendarIcon, name: "blueCalendar" },
	{ icon: SearchIcon, name: "Search" },
	{ icon: operatorPay, name: 'operatorPay' },
	{ icon: checkboxIcon, name: "Checkbox" },
	{ icon: BG, name: "BG" },
	{ icon: animation, name: "animation" },
	{ icon: pnr, name: "pnr" },
	{ icon: BottomFooterKuposLogo, name: "BottomFooterKuposLogo" },

	{ icon: OfficePhoneIcon, name: "OfficePhoneIcon" },

	{ icon: contactMail, name: "contactMail" },
	{ icon: contactHeadphone, name: "contactHeadphone" },
	{ icon: footerMail, name: "footerMail" },
	{ icon: footerPhone, name: "footerPhone" },
	{ icon: destinationBanner, name: "destinationBanner" },
	{ icon: galleryBanner, name: "galleryBanner" },
	{ icon: contactUsBanner, name: "contactUsBanner" },
	{ icon: aboutUsImage, name: "aboutUsImage" },
	{ icon: aboutUsBanner, name: "aboutUsBanner" },
	{ icon: tncBanner, name: "tncBanner" },
	{ icon: officeBanner, name: "officeBanner" },

	// accounts section
	{ icon: pay, name: "pay" },
	{ icon: backimage, name: "backimage" },
	{ icon: tarjetas, name: "tarjetas" },
	{ icon: viajes, name: "viajes" },
	{ icon: movimientos, name: "movimientos" },
	{ icon: wallet, name: "wallet" },
	{ icon: shareMoney, name: "shareMoney" },
	{ icon: camera, name: "camera" },
	{ icon: checkmark, name: "checkmark" },
	{ icon: tick, name: "tick" },
	{ icon: downArrow, name: "downArrow" },
	{ icon: loginblue, name: "loginblue" },
	{ icon: recoverpswd, name: "recoverpswd" },
	{ icon: success, name: "success" },
	{ icon: failure, name: "failure" },
	{ icon: heartOn, name: "heartOn" },
	{ icon: heartOff, name: "heartOff" },
	{ icon: registrationSuccess, name: "registrationSuccess" },
	{ icon: userIcon, name: "userIcon" },
	{ icon: warning, name: "warning" },
	// { icon: operatorPay, name: 'operatorPay' },
];

export default jeldresIcons;
