import React from 'react';

const ThemeTwoTitle = ({title,operator}) => {
  return (
    <div>
    <div style={{ textAlign:operator.operator_name === 'santarosa'? 'left':'center', fontSize: '20px' }}><br/><strong>{title}</strong></div>
    {operator.operator_name === 'santarosa'?
    <img src="/assets/santarosa/icons/red-line.png" alt="red-line" style={{ width: '10%', height: '8px' }} />:null}
    </div>
  )
}

export default ThemeTwoTitle;
