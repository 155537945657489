import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useState } from "react";

import Header from "../../common/header/Header";
import SantarosaContactForm from "./SantarosaContactForm";
import SantarosaFooter from "./santarosafooter";
import SantarosaServiceForm from "./SantarosaServiceForm";
import Tarbajaform from "./Tarbajaform";
import ThemeTwoHeader from "./ThemeTwoHeader";
import ThemeTwoTitle from "./ThemeTwoTitle";
import { connectData } from "../../../redux";
import { get } from "http";
import getIcons from "../../functions/getIcons/getIcons";

const SantaContactUsScreen = (props) => {
	const operator = JSON.parse(localStorage.getItem("op"));
	const [activeTab, setActiveTab] = useState("contact");

	useEffect(() => {
		const pathname = window.location.pathname;

		if (pathname === "/santarosa-contact") {
			setActiveTab("contact");
		} else if (pathname === "/santarosa-special-services") {
			setActiveTab("specialServices");
		} else if (pathname === "/santarosa-work-with-us") {
			setActiveTab("workWithUs");
		}
	}, []);


	const renderTabContent = () => {
		switch (activeTab) {
			case "contact":
				return (
					<div className="santafooterform">
						<SantarosaContactForm />
					</div>
				);
			case "specialServices":
				return (
					<div className="santafooterform">
						<SantarosaServiceForm />
					</div>
				);
			case "workWithUs":
				return (
					<div className="santafooterform">
						<Tarbajaform />
					</div>
				);
			default:
				return null;
		}
	};

	const title = (() => {
		switch (activeTab) {
			case "contact":
				return "Contáctanos";
			case "specialServices":
				return "Servicios especiales";
			case "workWithUs":
				return "Trabaja con nosotros";
			default:
				return "";
		}
	})();

	return (
		<IonPage>
			<IonContent>
				<ThemeTwoHeader />
				<div style={{ padding: 20 }}>

					<ThemeTwoTitle title={title} operator={operator} />

					<div style={styles.textBlock} >
						{activeTab === "contact" && (
							<div>
								<p>
									Completa el formulario de contacto con tus datos y el motivo de tu consulta.
								</p>

								<div style={styles.infoBlock} className="no-margin-right">
									<img
										src={getIcons("Headphone")}
										alt="phone"
										style={styles.icon}
									/>
									<span>Teléfono: {operator && operator.phone_number}</span>
								</div>

								<div style={styles.emailBlock} className="no-margin-right" >
									<img
										src={getIcons("Email")}
										alt="phone"
										style={styles.icon}
									/>
									<span>Email: {operator && operator.email}</span>
								</div>
							</div>
						)}
						{activeTab === "specialServices" && (
							<div>
								<p>
									¿Necesitas solicitar una cotización para servicios especiales?
									Te invitamos a ponerte en contacto por medio de nuestro formulario de cotizaciones y
									felices te atenderemos.
								</p>
								<div style={styles.infoBlock} className="no-margin-right">
									<img
										src={getIcons("Headphone")}
										alt="phone"
										style={styles.icon}
									/>
									<span>Teléfono : {operator && operator.phone_number}</span>
								</div>

								<div style={styles.emailBlock} className="no-margin-right">
									<img
										src={getIcons("Email")}
										alt="phone"
										style={styles.icon}
									/>
									<span>Email : {operator && operator.email}</span>
								</div>
							</div>
						)}
						{activeTab === "workWithUs" && (
							<div>
								<p>
									Completa el siguiente formulario y adjunta los siguientes documentos:
								</p>
								<ul className="document-list">
									<li>Currículum Vitae en formato PDF.</li>
									<li>Certificado de Antecedentes.</li>
									<li>Hoja de vida del conductor.</li>
									<li>Certificado histórico de cotizaciones AFP.</li>
								</ul>
								<p>
									También puedes postular enviando la documentación señalada al siguiente correo:<br />
									<a href="mailto:postulaciones@pullmansantarosa.cl" style={{ color: "#000" }}>
										<strong style={{ color: "#000" }}>postulaciones@pullmansantarosa.cl</strong></a>
								</p>
								<div style={styles.infoBlock} className="no-margin-right">
									<img
										src={getIcons("Headphone")}
										alt="phone"
										style={styles.icon}
									/>
									<span>Teléfono : {operator && operator.phone_number}</span>
								</div>

								<div style={styles.emailBlock} className="no-margin-right">
									<img
										src={getIcons("Email")}
										alt="phone"
										style={styles.icon}
									/>
									<span>Email : {operator && operator.email}</span>
								</div>
							</div>

						)}
					</div>

					{/* Tabs */}
					<div style={styles.tabContainer} className="santa-form-button">
						<button
							style={activeTab === "contact" ?
								{ ...styles.activeTab, ...styles.activeFirstTab } :
								{ ...styles.tab, ...styles.firstTab }}
							onClick={() => setActiveTab("contact")}
						>
							Formulario de contacto
						</button>
						<button
							style={activeTab === "specialServices" ? styles.activeTab : styles.tab}
							onClick={() => setActiveTab("specialServices")}
						>
							Servicios especiales
						</button>
						<button
							style={activeTab === "workWithUs" ?
								{ ...styles.activeTab, ...styles.activeLastTab } :
								{ ...styles.tab, ...styles.lastTab }}
							onClick={() => setActiveTab("workWithUs")}
						>
							Trabaja con nosotros
						</button>
					</div>

					{renderTabContent()}



				</div>

				<SantarosaFooter operator={props.data.operator} />
			</IonContent>
		</IonPage>
	);
};

// Styles
const styles = {
	tabContainer: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr 1fr",

	},
	tab: {
		flex: 1,
		textAlign: "center",
		cursor: "pointer",
		border: "none",
		background: "#CCCCCC",
		color: "#000",
	},
	firstTab: {
		borderTopLeftRadius: "5px",
	},
	lastTab: {
		borderTopRightRadius: "5px",
	},
	activeTab: {
		flex: 1,
		textAlign: "center",
		padding: "10px",
		cursor: "pointer",
		border: "none",
		background: "#b52c1f",

		fontWeight: "bold",
		color: "white",
	},
	activeFirstTab: {
		borderTopLeftRadius: "5px",
	},
	activeLastTab: {
		borderTopRightRadius: "5px",
	},
	contentContainer: {
		marginTop: "0px",
	},
	form: {
		display: "flex",
		flexDirection: "column",
		gap: "10px",
	},
	inputGroup: {
		display: "flex",
		gap: "10px",
	},
	input: {
		flex: 1,
		padding: "10px",
		borderRadius: "5px",
		border: "1px solid #ddd",
	},
	textarea: {
		width: "100%",
		padding: "10px",
		borderRadius: "5px",
		border: "1px solid #ddd",
		minHeight: "100px",
	},
	button: {
		backgroundColor: "red",
		color: "#fff",
		border: "none",
		padding: "10px",
		cursor: "pointer",
		fontWeight: "bold",
		borderRadius: "5px",
	},
	textBlock: {
		textAlign: "justify",
	},
	infoBlock: {
		display: "flex",
		alignItems: "center",
		gap: "10px",
		marginTop: "20px",
		marginRight: "20px",
	},
	emailBlock: {
		display: "flex",
		alignItems: "center",
		gap: "10px",

		marginTop: "10px",
		marginRight: "20px",
		marginBottom: "20px",
	},
	icon: {
		height: "30px",
		width: "30px",
	},
};

export default connectData()(SantaContactUsScreen);
