import "./ThemeTwoContactForm.css";

import {
	CarpoolSuccessFailureModal,
	PBInput,
	PButton,
} from "../../../components";
import React, { useState } from "react";

import { AppData, CommonService } from "../../../services";
import { connectData } from "../../../redux";
import getIcons from "../../functions/getIcons/getIcons";
import ReactDropdown from "../../../components/KuposDropdown";

const data = [ { id: 1, name: "Conductor" }, { id: 2, name: "Asistente" }, { id: 3, name: "Mecánico" }, ];

const TarbajaForm = (props) => {
	const initialState = {
		name: null,
		phone: null,
		residencia:null,
        licencia:null,
        cargo:null,
        document:null,
        nameError: false,
        phoneError: false,
        residenciaError:false,
        licenciaError:false,
        cargoError:false,
        documentError:false,
		email: null,
		emailError: false,
	};
	const [state, setState] = useState(initialState);
    const [selectedValue, setSelectedValue] = useState("");
     const handleSelectChange = (selectedItem) => 
        { setSelectedValue(selectedItem);
        inpChange(selectedItem.name, "cargo");
         };
	const operator =
		JSON.parse(localStorage.getItem("op")) || props.data.operator;

		const inpChange = (value, type) => {
			if (type === "phone") {
				value = CommonService.phoneNumberFormat(value);
				setState({
					...state,
					[type]: value,
					[type + "Error"]: false,
				});
			} else if (type === "file") {
				setState({
					...state,
					document: value, 
					documentError: false, 
				});
			} else {
				setState({
					...state,
					[type]: value,
					[type + "Error"]: false,
				});
			}
		};
		

	const validate = () => {
		let errorCount = 0;
		if (!state.name) {
			setState({
				...state,
				nameError: true,
				nameErrorMessage: "Ingresa tu nombre",
			});
			errorCount++;
		} else if (!state.phone) {
			setState({
				...state,
				phoneError: true,
				phoneErrorMessage: "Ingresa tu número de teléfono",
			});
			errorCount++;
		} else if (!state.email) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingresa tu correo electrónico",
			});

		} else if (state.email && !CommonService.isEMailValid(state.email)) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingrese un email valido",
			});
			errorCount++;
		}else if (!state.residencia) {
            setState({
                ...state,
                commentError: true,
                commentErrorMessage: "Ingresa tu ciudad de residencia",
            });
            errorCount++;
        } else if (!state.licencia) {
            setState({
                ...state,
                licenciaError: true,
                licenciaErrorMessage: "Ingresa tu tipo de licencia de conducir",
            });
            errorCount++;
        }else if (!state.cargo) {
            setState({
                ...state,
                cargoError: true,
                cargoErrorMessage: "Ingresa el cargo al que postulas",
            });
            errorCount++;
        }else if (!state.document) {
            setState({
                ...state,
                documentError: true,
                documentErrorMessage: "Adjunta tu documento",
            });
            errorCount++;
        }


		if (errorCount > 0) {
			return false;
		} else {
			return true;
		}
	};

	const onSubmit = () => {
		if (!validate()) {
			return;
		}

  
		let data = {
			name: state.name,
			telephone: state.phone,
			email: state.email,
			operator_id: operator.operator_id,
            city_of_residence:state.residencia,
            licence_type:state.licencia,
            position_applying_for:state.cargo,
            // resume:state.document,
		};

	
		const formData = new FormData();
		Object.keys(data).forEach(key => {
			formData.append(key, data[key]);
		});
		formData.append('resume', state.document);
		

		 
		// const operatorsRequiringLastName = []; // add operator name in Array for which you need last_name.

		// if (operatorsRequiringLastName.includes(operator.operator_name) && state.last_name) {
		// 	data.last_name = state.last_name;
		// }
		// if (operator && (operator.operator_name === "tarapaca" || operator.operator_name === "santamaria" || operator.operator_name === "kennybus" || operator.operator_name === "barriaghisoni" || operator.operator_name === "cormarbus" || operator.operator_name === "serenamar" || operator.operator_name === "jetsur" || operator.operator_name === "viatur" || operator.operator_name === "combarbala" || operator.operator_name === "expresosantacruz" || operator.operator_name === "biaggini" || operator.operator_name === "thaebus" || operator.operator_name === "lineaazul" || operator.operator_name ==="santarosa")) {


		
		fetch(AppData.BASE_URL + "api/work_with_us", {
			method: "POST",
			body: formData,  
		  })
			.then(res => res.json())  
			.then(data => onContactInfoResponse(data))  
			.catch(err => {
			  console.error("Error:", err); 
			  onContactInfoResponse({ success: false, message: "Error en la solicitud" });  
			});
		  
		  const onContactInfoResponse = (res) => {
			if (res && res.message) {
			  
			  setState({
				...state,
				showModal: true,
				responseMessage: res.message,  
				status: res.status,  
			  });
			} else {
			  setState({
				...state,
				showModal: true,
				responseMessage: "Ha ocurrido un error. Inténtalo nuevamente.",
				status: "error",  
			  });
			}
		  };
	}
		  
	

	return (
		operator && (
			<div className="ThemeTwoContactForm bold-text" style={{marginTop:-30,marginInline:-20}}>
				<div >
					
					<div className="contact">
						<div
							className="contact-bottom"
							style={{
								backgroundColor: operator && operator.color_codes && operator.color_codes.primary_color
                                ? operator.color_codes.primary_color : "",
							}}
						>
							<div className="align-contact-inputs">
								<div className="contact-input-item">
									<style>
										{`
                    .ThemeTwoContactForm .contact .contact-bottom .contact-input-label {
                        color: ${operator &&
												operator.color_codes &&
												operator.color_codes.secondary_text_color
												? operator.color_codes.secondary_text_color
												: ""};
                    }
                    `}
									</style>

									<div className="contact-input-label" style={{ fontSize: operator.operator_name === "biaggini" ? "14px" : "inherit" }}>
										Nombre y apellido
									</div>

									<PBInput
										value={state.name}
										onChange={(text) => inpChange(text, "name")}
										error={state.nameError ? true : false}
										errorMessage={state.nameError ? state.nameErrorMessage : ""}
										containerStyle={{
											padding: "0",
											border: 0,
										}}
										inputClass={{
											padding: "5px",
										}}
									/>
								</div>
										<div className="contact-input-item">
											<div className="contact-input-label">Teléfono</div>
											<PBInput
												value={state.phone}
												onChange={(text) => inpChange(text, "phone")}
												error={state.phoneError ? true : false}
												errorMessage={state.phoneError ? state.phoneErrorMessage : ""}
												containerStyle={{ padding: "0", border: 0 }}
												inputClass={{ padding: "5px" }}
											/>
										</div>

										<div className="contact-input-item">
											<div className="contact-input-label">Correo Electrónico</div>
											<PBInput
												value={state.email}
												onChange={(text) => inpChange(text, "email")}
												error={state.emailError ? true : false}
												errorMessage={state.emailError ? state.emailErrorMessage : ""}
												containerStyle={{ padding: "0", border: 0 }}
												inputClass={{ padding: "5px" }}
											/>
										</div>


                                        <div className="contact-input-item">
                                            <div className="contact-input-label">ciudad de residencia</div>
                                            <PBInput
                                                value={state.residencia}
                                                onChange={(text) => inpChange(text, "residencia")}
                                                error={state.commentError ? true : false}
                                                errorMessage={state.commentError ? state.commentErrorMessage : ""}
                                                containerStyle={{ padding: "0", border: 0 }}
                                                inputClass={{ padding: "5px" }}
                                                />
                                        </div>

                                        <div className="contact-input-item">
                                            <div className="contact-input-label">Tipo de licencia de conducir</div>
                                            <PBInput
                                                value={state.licencia}
                                                onChange={(text) => inpChange(text, "licencia")}
                                                error={state.licenciaError ? true : false}
                                                errorMessage={state.licenciaError ? state.licenciaErrorMessage : ""}
                                                containerStyle={{ padding: "0", border: 0 }}
                                                inputClass={{ padding: "5px" }}
                                            />
							            </div>

                                        <div className="contact-input-item">
                                            <div className="contact-input-label">Cargo al que postula</div>
                                            <div  style={{paddingBottom:20}} > 
											<PBInput
												value={state.cargo}
												onChange={(text) => inpChange(text, "cargo")}
												error={state.cargoError ? true : false}
												errorMessage={state.cargoError ? state.cargoErrorMessage : ""}
												containerStyle={{ padding: "0", border: 0 }}
												inputClass={{ padding: "5px" }}
											/>
                                            </div>
                            
                                           {/* <div  style={{paddingBottom:20}} > 
                                            <ReactDropdown 
                                            data={data} 
                                            onSelectChange={handleSelectChange} 
                                            placeholder=""
                                            value={selectedValue} 
                                             noBorder={false} 
                                             disabled={false} /> 
                                             </div> */}
                                            
                                         </div> 


                            <div className="contact-input-item" style={{ position: "relative", display: "flex", alignItems: "center", background: "#fff", borderRadius: "10px", padding: "10px 15px", border: "none", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}>
                               <span style={{ flex: 1, fontSize: "14px", color: "#000",padding:"3px" }}>{state.document ? state.document.name : "Adjuntar documento"}</span>
                               <label htmlFor="file-input" style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                                  <img src="/assets/santarosa/icons/pin.png" alt="Attach" style={{ width: "20px", height: "20px", marginLeft: "10px" }} />
                              </label>
                               <input
                                id="file-input"
                                type="file"
								accept=".pdf,.doc,.docx"
                                onChange={(e) => {
									const file = e.target.files[0];
									if (file) {
										inpChange(file, "document");
									}
								}}
                                style={{
                                   position: "absolute",
                                   left: 0,
                                   top: 0,
                                   width: "100%",
                                   height: "100%",
                                   opacity: 0,
                                   cursor: "pointer",
                                }}
                                />
                            </div>

									
							</div>

					

							<div
								className="contact-button"
								style={{
									marginLeft: -10,
									marginTop: 10,
									paddingTop: 20,
									color:"red"
								}}
							>
								
							<PButton
							style={{
								backgroundColor:"#fff",
							}}
							button1FontColor={operator && operator.color_codes && operator.color_codes.primary_color ? operator.color_codes.primary_color : ""}
							title={"ENVIAR"}
							 onPress={onSubmit}
							  />
								
						
							</div>
						</div>
					</div>

				</div>
				<CarpoolSuccessFailureModal
					showModal={state.showModal}
					success={state.status ? true : false}
					smallIcon={true}
					bodyText={state.responseMessage}
					buttonText={"OK"}
					onButtonPress={() => {
						setState({
							showModal: false,
						});
						props.history.push("/");
					}}
				/>
			</div>
		)
	);
};

export default connectData()(TarbajaForm);