import React, { PureComponent } from "react";

import { connectData } from "../../redux";
import { dimensions, commonStyles } from "../../theme";
import { CommonService, DateSerivce } from "../../services";
import {
  BGCarpoolInnerBottom,
  CarpoolHeader,
  PBCard,
  PBText,
  PBModal,
  PBTouchable,
  PButton,
} from "../../components";
import { IonPage, IonContent, withIonLifeCycle } from "@ionic/react";
import { Link } from "react-router-dom";
import DateService from "../../services/dateService";

class CarpoolTripDetailsScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedTicket: null,
      ticketDetails: null,
    };
  }

  ionViewWillEnter() {
    this.setState({ ticketDetails: null });
    this.loadMyTicketDetails();
  }

  loadMyTicketDetails = () => {
    this.props.getTicketDetails({
      callback: result => this.onResponse(result),
      pnrNumber: this.props.match.params.pnr,
      path: "is_mobile_app=true",
    });
  };

  onResponse = result => {
    // console.log("Ticket got from backend", result);
    if (result.data && result.data.error) {
      this.refs.toast.show(result.data.error);
    } else if (result.data && result.data.success && result.data.body) {
      this.setState({ ticketDetails: result.data.body });
    } else if (result.data && !result.data.success && result.data.body) {
    }
  };

  componentDidMount() {
    this.getSelectedTicket();
  }

  getSelectedTicket = () => {
    this.props.data.myTickets.map(ticket =>
      // console.log("params logged in", this.props.match.params.pnr)
      ticket.pnr_number == this.props.match.params.pnr
        ? this.setState({ selectedTicket: ticket })
        : console.log("no message"),
    );
    // console.log("Ticket selected above", this.state.selectedTicket);
  };

  cancelTrip = () => {
    console.log("At cancel call");
    sessionStorage.setItem(
      "cancelTicketDetails",
      JSON.stringify(this.state.ticketDetails),
    );
    this.props.history.push(
      "/bus-cancel-trip/" + this.state.ticketDetails.pnr_number,
    );
  };

  isPastDate(date, time) {
    let d = new Date(date);
    // console.log("Date Formed", d)
    // let d = new Date(date + (time ? `T${time}:00` : "T00:00:00"));
    let d1 = new Date();
    if (d - d1 < 0) {
      console.log("....true");
      return true;
    }
    return false;
    // if (date < DateSerivce.getTodayString("yyyy-mm-dd")) {
    //   console.log("Date is", date);
    //   return false;
    // }
    //  console.log("true");
    // return true;
  }

  fourHourRange = (date, time) => {
    console.log("Current date", DateSerivce.getTodayString());
    console.log("Current time", DateService.getCurrentTime(false));
    console.log("Ticket date", date);
    console.log("Ticket time", time);

    var parts = DateSerivce.getTodayString().split("-");
    var currentDate = Number(parts[0] + parts[1] + parts[2]);
    parts = date.split("/");
    var ticketDate = Number(parts[0] + parts[1] + parts[2]);

    console.log("Current date and ticket date", currentDate, ticketDate);

    if (ticketDate == currentDate) {
      console.log("Inside if");
      if (
        time.split(":")[0] - DateService.getCurrentTime(false).split(":")[0] >
        4
      ) {
        console.log("Inside if if");
        return true;
      } else {
        console.log("Inside if else");
        return false;
      }
    } else if (ticketDate > currentDate) {
      console.log("Inside else");
      return true;
    }

    console.log("Current date and ticket date", currentDate, ticketDate);
  };

  render() {
    const operator =
      JSON.parse(localStorage.getItem("op")) || this.props.data.operator;
    console.log("Ticket Details", this.state.ticketDetails);
    return (
      <IonPage>
        <IonContent>
          <div className="">
            {/* <BGCarpoolInnerBottom /> */}
            <div className="my-account-header">
              <div
                className="header-back-button"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  className="qr-back-arrow"
                  src="../../assets/carpool/icon_back_arrow.png"
                  alt=""
                />
              </div>
              <div className="my-account-header-label display-flex">
                <PBText fontSize={"font19"} fontColor={"dark-font"}
																fontStyle={
																	operator && operator.operator_name === "busnorte"
																			? "side-menu-new-bold-font"
																			: ""
															}
																>
                  Detalles
                </PBText>
                <div className="white-space"></div>
                <PBText
                  fontSize={"font19"}
                  fontColor={"dark-font"}
                  fontStyle={
																			operator && operator.operator_name === "busnorte"
																					? "new-bold-font"
																					: "bold-text"
																	}
                >
                  de viaje
                </PBText>
              </div>
              <div>&nbsp;</div>
            </div>

            <div
              className="position-relative kupos-card"
              style={{ marginTop: 5 }}
            >
              <div style={styles.itemContainer}>
                <PBText
                  fontSize={"font14"}
                  fontColor={"dark-font"}
                  fontStyle={
                    operator && operator.operator_name === "busnorte"
                      ? "new-bold-font"
                      : "bold-font"
                  }
                >
                  Empresa
                </PBText>
                <PBText
                  style={styles.text}
                  fontStyle={
                    operator && operator.operator_name === "busnorte"
                      ? "side-menu-new-bold-font"
                      : ""
                  }
                >
                  {this.state.ticketDetails
                    ? this.state.ticketDetails.operator_service_name
                    : null}
                </PBText>
              </div>

              <div className="payment-history-h-line"></div>
              <div style={styles.itemContainer}>
                <PBText
                  fontSize={"font14"}
                  fontColor={"dark-font"}
                  fontStyle={
                    operator && operator.operator_name === "busnorte"
                      ? "new-bold-font"
                      : "bold-font"
                  }
                >
                  N° reserva
                </PBText>
                <PBText
                  style={styles.text}
                  fontStyle={
                    operator && operator.operator_name === "busnorte"
                      ? "side-menu-new-bold-font"
                      : ""
                  }
                >
                  {this.state.ticketDetails
                    ? this.state.ticketDetails.pnr_number
                    : null}
                </PBText>
              </div>
              <div style={styles.itemContainer}>
                <div className="payment-history-h-line"></div>
                <PBText
                  fontSize={"font14"}
                  fontColor={"dark-font"}
                  fontStyle={
                    operator && operator.operator_name === "busnorte"
                      ? "new-bold-font"
                      : "bold-font"
                  }
                >
                  Detalles de viaje
                </PBText>

                <div className="display-flex">
                  <PBText style={styles.text}>•</PBText>
                  <div className="white-space"></div>
                  <PBText
                    style={styles.text}
                    fontStyle={
                      operator && operator.operator_name === "busnorte"
                        ? "side-menu-new-bold-font"
                        : ""
                    }
                  >
                    {this.state.ticketDetails
                      ? this.state.ticketDetails.origin_name +
                        " (" +
                        this.state.ticketDetails.boarding_details.address +
                        " - " +
                        this.state.ticketDetails.boarding_details.time +
                        ")"
                      : null}
                  </PBText>
                </div>

                <div className="display-flex">
                  <PBText style={styles.text}>•</PBText>
                  <div className="white-space"></div>
                  <PBText
                    style={styles.text}
                    fontStyle={
                      operator && operator.operator_name === "busnorte"
                        ? "side-menu-new-bold-font"
                        : ""
                    }
                  >
                    {this.state.ticketDetails
                      ? this.state.ticketDetails.destination_name +
                        " (" +
                        this.state.ticketDetails.arrival_details.address +
                        " - " +
                        this.state.ticketDetails.arrival_details.time +
                        ")"
                      : null}
                  </PBText>
                </div>

                {/* <div className="display-flex">
                  <PBText style={styles.text}>
                    Conductor: {this.carpoolSelectedOngoingTrip.driver.name}
                    Conductor:
                  </PBText>
                  <div className="white-space"></div>
                  <PBText style={styles.text}>
                    {this.state.selectedTicket
                      ? ticketInside.destination_name
                      : null}
                  </PBText>
                </div> */}

                {/* <div className="display-flex">
                                      <PBText style={styles.text}>Asientos:</PBText>
                                      <div className="white-space"></div>
                                      <PBText style={styles.text}>
                                        {this.state.selectedTicket
                                          ? ticketInside.destination_name
                                          : null}
                                      </PBText>
                                    </div> */}

                {/* <PBText style={styles.text}>
                  Conductor: {this.carpoolSelectedOngoingTrip.driver.name}
                </PBText>

                <PBText style={styles.text}>
                  Auto: {this.carpoolSelectedOngoingTrip.driver.vehicle_model}
                </PBText>
                <PBText style={styles.text}>
                  Asientos: {this.carpoolSelectedOngoingTrip.total_seat}
                </PBText> */}
              </div>

              {/* <div className="payment-history-h-line"></div> */}
              {/* <div style={styles.itemContainer}>
                <PBText
                  fontSize={"font14"}
                  fontColor={"dark-font"}
                  fontStyle={"bold-font"}
                >
                  Tu calificación
                </PBText>
                <PBText style={styles.text}>Excelente</PBText>
              </div> */}

              <div className="payment-history-h-line"></div>

              <div className="display-flex" style={styles.itemContainer}>
                <div style={{ flex: 1 }}>
                  <PBText
                    fontSize={"font14"}
                    fontColor={"dark-font"}
                    fontStyle={
                      operator && operator.operator_name === "busnorte"
                        ? "new-bold-font"
                        : "bold-font"
                    }
                  >
                    Fecha de viaje
                  </PBText>
                  <PBText
                    style={styles.text}
                    fontStyle={
                      operator && operator.operator_name === "busnorte"
                        ? "side-menu-new-bold-font"
                        : ""
                    }
                  >
                    {this.state.ticketDetails
                      ? this.state.ticketDetails.travel_date
                      : null}
                  </PBText>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <PBText
                    fontSize={"font14"}
                    fontColor={"dark-font"}
                    fontStyle={
                      operator && operator.operator_name === "busnorte"
                        ? "new-bold-font"
                        : "bold-font"
                    }
                  >
                    Hora de viaje
                  </PBText>
                  <PBText
                    style={styles.text}
                    fontStyle={
                      operator && operator.operator_name === "busnorte"
                        ? "side-menu-new-bold-font"
                        : ""
                    }
                  >
                    {/* {console.log("Ticket details", this.state.selectedTicket)} */}
                    {this.state.ticketDetails
                      ? this.state.ticketDetails.depart_time +
                        DateSerivce.ampmOnly(
                          this.state.ticketDetails.depart_time,
                        )
                      : null}
                    {/* {DateSerivce.getTime(
                      new Date(
                        this.carpoolSelectedOngoingTrip.travel_date * 1000
                      )
                    )} */}
                  </PBText>
                </div>
              </div>

              <div style={{ marginTop: 10 }}>
                <PBText
                  fontSize={"font14"}
                  fontColor={"dark-font"}
                  fontStyle={
                    operator && operator.operator_name === "busnorte"
                      ? "new-bold-font"
                      : "bold-font"
                  }
                >
                  Detalles del pasajero
                </PBText>
              </div>

              {this.state.ticketDetails &&
              this.state.ticketDetails.seat_Details ? (
                Object.keys(
                  this.state.ticketDetails.seat_Details.seat_details,
                ).map((val, key) => (
                  <div style={styles.itemContainer} key={key}>
                    <div className="display-flex space-between">
                      <PBText
                        fontSize={"font12"}
                        fontColor={"dark-font"}
                        fontStyle={
                          operator && operator.operator_name === "busnorte"
                            ? "new-bold-font"
                            : "bold-font"
                        }
                      >
                        Nombre
                      </PBText>
                      <PBText
                        fontSize={"font12"}
                        fontColor={"dark-font"}
                        fontStyle={
                          operator && operator.operator_name === "busnorte"
                            ? "new-bold-font"
                            : "bold-font"
                        }
                      >
                        N° Asiento
                      </PBText>
                    </div>
                    <div className="display-flex space-between">
                      <PBText
                        style={styles.text}
                        fontStyle={
                          operator && operator.operator_name === "busnorte"
                            ? "side-menu-new-bold-font"
                            : ""
                        }
                      >
                        {/* {console.log(this.state.ticketDetails)} */}
                        {this.state.ticketDetails.seat_Details.seat_details[val]
                          ? this.state.ticketDetails.seat_Details.seat_details[
                              val
                            ].name +
                            (this.state.ticketDetails.seat_Details.seat_details[
                              val
                            ].last_name
                              ? this.state.ticketDetails.seat_Details
                                  .seat_details[val].last_name
                              : "")
                          : null}
                      </PBText>
                      <PBText style={styles.text}>
                        {/* {console.log("Ticket details", this.state.selectedTicket)} */}
                        {this.state.ticketDetails.seat_Details.seat_details[val]
                          ? this.state.ticketDetails.seat_Details.seat_details[
                              val
                            ].seat_number
                          : null}
                      </PBText>
                    </div>
                  </div>
                ))
              ) : (
                <div className="display-flex" style={styles.itemContainer}>
                  <div style={{ flex: 1 }}>
                    <PBText
                      fontSize={"font12"}
                      fontColor={"dark-font"}
                      fontStyle={
                        operator && operator.operator_name === "busnorte"
                          ? "new-bold-font"
                          : "bold-font"
                      }
                    >
                      Nombre
                    </PBText>
                    <PBText
                      style={styles.text}
                      fontStyle={
                        operator && operator.operator_name === "busnorte"
                          ? "side-menu-new-bold-font"
                          : ""
                      }
                    >
                      {/* {console.log(this.state.ticketDetails)} */}
                      {this.state.ticketDetails
                        ? this.state.ticketDetails.name +
                          (this.state.ticketDetails.last_name
                            ? this.state.ticketDetails.last_name
                            : "")
                        : null}
                    </PBText>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <PBText
                      fontSize={"font12"}
                      fontColor={"dark-font"}
                      fontStyle={
                        operator && operator.operator_name === "busnorte"
                          ? "new-bold-font"
                          : "bold-font"
                      }
                    >
                      N° Asiento
                    </PBText>
                    <PBText
                      style={styles.text}
                      fontStyle={
                        operator && operator.operator_name === "busnorte"
                          ? "side-menu-new-bold-font"
                          : ""
                      }
                    >
                      {/* {console.log("Ticket details", this.state.selectedTicket)} */}
                      {this.state.ticketDetails
                        ? this.state.ticketDetails.seat_nos
                        : null}
                    </PBText>
                  </div>
                </div>
              )}

              <div className="payment-history-h-line"></div>
              {/* {(this.state.ticketDetails &&
                this.state.ticketDetails.status_label.toLowerCase() ==
                  "cancelado") ||
              (this.state.ticketDetails &&
                this.state.ticketDetails.status_label.toLowerCase() ==
                  "cancelled") ? null : ( */}

              {/* )} */}

              <div className="display-flex">
                <div style={{ flex: 1 }}>
                  <PBText
                    fontSize={"font15"}
                    fontColor={"dark-font"}
                    fontStyle={
                      operator && operator.operator_name === "busnorte"
                        ? "new-bold-font"
                        : "bold-font"
                    }
                    dark={true}
                  >
                    TOTAL
                  </PBText>
                </div>
                <div className="flex-end">
                  <PBText
                    fontSize={"font15"}
                    fontColor={"dark-font"}
                    fontStyle={
                      operator && operator.operator_name === "busnorte"
                        ? "new-bold-font"
                        : "bold-font"
                    }
                    dark={true}
                  >
                    {this.state.ticketDetails
                      ? "CLP $" + this.state.ticketDetails.total_booked_amount
                      : "CLP $0"}
                  </PBText>
                </div>
              </div>

              <div
                style={{
                  marginVertical: 10,
                  paddingTop: 15,
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {/* <a>{"descargar pasaje".toUpperCase()} </a> */}
                {this.state.ticketDetails &&
                this.state.ticketDetails.status.toUpperCase() != "CANCELADO" ? (
                  <a
                    href={
                      this.state.ticketDetails
                        ? this.state.ticketDetails.print_pdf_url
                        : null
                    }
                    target="_blank"
                  >
                    <PBText
                      fontSize={"font14"}
                      // fontColor={"primary-font"}
                      fontStyle={
                        operator && operator.operator_name === "busnorte"
                          ? "new-bold-font"
                          : "bold-font"
                      }
                      center={true}
                      style={{
                        color:
                          this.props.data.operator &&
                          this.props.data.operator.color_codes &&
                          this.props.data.operator.color_codes.button_color
                            ? this.props.data.operator.color_codes.button_color
                            : "",
                      }}
                    >
                      {"descargar pasaje".toUpperCase()}
                    </PBText>
                  </a>
                ) : null}
              </div>
            </div>

            {this.state.ticketDetails &&
            this.state.ticketDetails.status.toUpperCase() !== "CANCELADO"
             &&
            !this.isPastDate(
              this.state.ticketDetails.travel_date,
              this.state.ticketDetails.depart_time,
            ) &&
            this.fourHourRange(
              this.state.ticketDetails.travel_date,
              this.state.ticketDetails.depart_time,
            )
             ? (
              <div
                className="my-trips-detailed-body-bottom text-center"
                onClick={this.cancelTrip}
                style={{
                  background:
                    this.props.data.operator &&
                    this.props.data.operator.color_codes &&
                    this.props.data.operator.color_codes.secondary_color
                      ? this.props.data.operator.color_codes.secondary_color
                      : "",
                }}
              >
                <PBText fontSize={"font15"} fontColor={"white-text"}>
                  CANCELAR VIAJE
                </PBText>
              </div>
            ) : null}

            {/* {((this.state.ticketDetails &&
              this.state.ticketDetails.status &&
              this.state.ticketDetails.status.toUpperCase().indexOf("CANCEL") <
                0) ||
              (this.state.ticketDetails &&
                this.state.ticketDetails.statusindexOf("PARTIAL") > -1)) &&
            this.state.ticketDetails &&
            !this.isPastDate(this.state.ticketDetails.travel_date) ? (
              <Link
                to={`/bus-cancel-trip/:${this.state.ticketDetails.pnr_number}`}
              >
                <div className="my-trips-detailed-body-bottom text-center">
                  <PBText
                    onClick={this.cancelTrip}
                    fontSize={"font15"}
                    fontColor={"white-text"}
                  >
                    CANCELAR VIAJE
                  </PBText>
                </div>
              </Link>
            ) : null} */}
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

const styles = {
  flex: {
    flex: 1,
  },
  tripDetails: {
    marginTop: 10,
    alignItems: "center",
  },
  itemContainer: {
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
    marginTop: 10,
  },
  text: {
    fontSize: 14,
    marginTop: 2,
    color: "#464647",
  },
};

export default connectData()(withIonLifeCycle(CarpoolTripDetailsScreen));
