import './ThemeTwoFooter.css';

import React from 'react';
import getIcons from '../../functions/getIcons/getIcons';

const SantarosaFooter = ({ operator }) => {
	return (
		operator && (
			<div>
			<div className='ThemeTwoFooter '>
				<div
					style={{
						background:
							operator &&
								operator.color_codes &&
								operator.color_codes.footer_color
								? operator.color_codes.footer_color
								: '',
					}}
					className='footer'
				>
					<div className=''>
						<a href='/'>
							<img
								src={
									operator && operator.footer_logo ? operator.footer_logo : ''
								}
								// alt={this.props.t('TRANSLATIONS.VIATUR_LOGO')}
								style={{
									// marginTop: '0',
									width:
										operator && operator.footer_logo_width
											? operator.footer_logo_width
											: '250px',
									height: 'auto',
								}}
								alt=''
							/>
						</a>
					</div>
					
						<span style={{color:"#fff"}}>
							<strong >Información útil </strong>
						</span>
					
					<div className='footer-links'>
						<div className='footer-links-item'>
							<a
								href='/terms'
								style={{
									color:
										operator &&
											operator.color_codes &&
											operator.color_codes.footer_text_color
											? operator.color_codes.footer_text_color
											: '#fff',
								}}
							>
								Términos y condiciones
							</a>
						</div>
						<div className='footer-links-item'>
							<a
								href='/sanatarosa-privacy-policy'
								style={{
									color:
										operator &&
											operator.color_codes &&
											operator.color_codes.footer_text_color
											? operator.color_codes.footer_text_color
											: '#fff',
								}}
							>
								Políticas de privacidad
							</a>
						</div>
						</div>

						<span style={{color:"#fff"}}><strong>Síguenos</strong></span>

						<div className='footer-links'>
							<div className='footer-links-item'>
								<a
									href="https://www.facebook.com/santarosapullman/"
									style={{
										color:
											operator &&
												operator.color_codes &&
												operator.color_codes.footer_text_color
												? operator.color_codes.footer_text_color
												: '#fff',
									}}
								>
									<img
										src={getIcons('facebook', operator)}
										alt='facebook'
										style={{ width: 20, height: 20 }}
									/>
								</a>
								</div>
								<div className='footer-links-item'>
									<a
										href="https://www.instagram.com/pullmansantarosa/"
										style={{
											color:
												operator &&
													operator.color_codes &&
													operator.color_codes.footer_text_color
													? operator.color_codes.footer_text_color
													: '#fff',
										}}
									>
										<img
											src={getIcons('instagram', operator)}
											alt='instagram'
											style={{ width: 20, height: 20 }}
										/>
									</a>
						</div>
						<div className='footer-links-item'>
							<a
								href="https://x.com/BusesSantaRosa"
								style={{
									color:
										operator &&
											operator.color_codes &&
											operator.color_codes.footer_text_color
											? operator.color_codes.footer_text_color
											: '#fff',
								}}
							>
								<img
									src={getIcons('twitter', operator)}
									alt='twitter'
									style={{ width: 20, height: 20 }}
								/>
							</a>
							</div>
                  </div>
					

				</div>
			</div>
			{/* {operator && operator.show_bottom_footer ? ( */}
					<ShowBottomFooter operator={operator} />
				 {/* ) : null} */}
			</div>
			
		)
	);
};

const ShowBottomFooter = ({ operator }) => {
	return (
		<div
			className='d-flex-c f-13 p-2'
			style={{
				// padding: 10,
				textAlign: 'center',
				background:"#fff"
			}}
		>
			<div
				style={{
					color:"#000",
					padding: 5,
				}}
			>
				<span className='avenir-semi font12'>{operator.name} {new Date().getFullYear()} ©</span>
				<span className='poppins-ligh font12'>
					- Todos los derechos reservados © | <br></br> Mapa del sitio | Powered by
					<a href='https://kupos.cl' target='_blank' style={{ color: '#000', fontWeight: 'bold' }}>
						{' '} kupos.cl
					</a>
				</span>

			</div>

		</div>
	);
};

export default SantarosaFooter;
