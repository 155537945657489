import React from "react";
import { IonContent, IonPage } from "@ionic/react";

import { connectData } from "../../redux";
import HeroSection from "../common/HeroSection/HeroSection";
import getIcons from "../functions/getIcons/getIcons";
import ThemeTwoFooter from "./common/ThemeTwoFooter";
import ThemeTwoContactForm from "./common/ThemeTwoContactForm";
import Header from "../common/header/Header";
import ThemeTwoTitle from "./common/ThemeTwoTitle";
import SantarosaFooter from "./common/santarosafooter";
import ThemeTwoHeader from "./common/ThemeTwoHeader";

const ThemeTwoPrivacyPolicyScreen = (props) => {

  const createMarkup = (data) => {
    return { __html: data };
  };
  return (
    <IonPage>
      <IonContent>
        <ThemeTwoHeader/>
        <HeroSection
          height={"30vh"}
          heroImage={getIcons("Banner")}
          title={"Política de privacidad"}
          description={"Home / Política de privacidad"}
        />
         <div style={{paddingLeft:20}}>
          <ThemeTwoTitle operator={props.data.operator} title='Política de privacidad' />
          </div>
        <div style={{ width: "90%", marginLeft: "5%",textAlign:"justify" }}>
          <div
            dangerouslySetInnerHTML={createMarkup(
              props.data &&
                props.data.operator &&
                props.data.operator.privacy_policy
                ? props.data.operator.privacy_policy
                : ""
            )}
            className="lh-1-3"
            style={{ fontSize: "13px" }}
          />
        </div>

        {props.data.operator.operator_name !== "santarosa" ?
        <ThemeTwoFooter operator={props.data.operator} />
        : <SantarosaFooter operator={props.data.operator} />}
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeTwoPrivacyPolicyScreen);
