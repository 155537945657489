const AppData = {
  // AT_URL: "https://at.kupos.cl/",
  // AT_URL: "http://3.81.28.102:4000/",
  AT_URL: "https://at.kupos.cl/",
  BASE_URL: "https://api.pasajebus.com/",
  // BASE_URL: "https://pbstage.kupas.cl/",
  // BASE_URL: "https://api.pasajebus.com/",
  ONE_SIGNAL_APP_ID: "234fd2db-ea84-4b59-ab4b-28a3ea826474",
  // BIP_URL: "https://stg2.bip.kupos.cl/",
  SENDER_ID: "730867902568",
  CARPOOL_URL: " ",
  MAP_API_KEY: "AIzaSyAZwHr1fuhn2Jh5dsENAPuGJJT4iTJpdCM",
  // FB_APP_ID: "177184106959636",//
  FB_APP_ID: "409783556699660",
  // BASE_URL_V2: "https://at.kupos.cl/api/v2/",
  // BASE_URL_V3: "https://at.kupos.cl/api/v3/",
  BASE_URL_V2: "https://at.kupos.cl/api/v2/",
  BASE_URL_V3: "https://at.kupos.cl/api/v3/",
  X_OP_APP_DOMAIN: window.location.host.replace('www.',''),
  X_OPSITE: true
};
export default AppData;
