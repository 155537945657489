import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { connectData } from '../../../redux';
import { CommonService } from '../../../services';
import HeroSection from '../../common/HeroSection/HeroSection';
import getIcons from '../../functions/getIcons/getIcons';
// import ThemeThreeTitle from '../common/ThemeThreeTitle';
// import ThemeTwoFooter from './ThemeTwoFooter';
import ThemeTwoHeader from './ThemeTwoHeader';
import ThemeTwoTitle from './ThemeTwoTitle';
import ThemeTwoFooter from './ThemeTwoFooter';
import SantarosaFooter from './santarosafooter';

const ThemeTwoTnCScreen = (props) => {
  const operator = CommonService.getLocal('op') || props.data.operator;

  const createMarkup = (data) => {
    return { __html: data };
  };

  return (
    <IonPage>
      <IonContent>
        <ThemeTwoHeader />
        <HeroSection
          title='Términos y condiciones'
          height={'45vh'}
          description='Home / Términos y condiciones        '
          heroImage={getIcons('Banner', operator)}
        />
         <div style={{paddingLeft:20}}>
        <ThemeTwoTitle operator={operator} title='Términos y condiciones' />
        </div>
        <div className='new-container mb10 mt10'>
          <div
            dangerouslySetInnerHTML={createMarkup(
              operator.terms ? operator.terms : ''
            )}
            className='lh-1-3  justify-text'
          />
        </div>
        {operator.operator_name !== "santarosa" ?
        <ThemeTwoFooter operator={operator} />
        : <SantarosaFooter operator={operator} />}
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeTwoTnCScreen);
