import React from 'react';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';
import { menuController } from '@ionic/core';

const Header = ({ showBackground = true, data }) => {
	const operator = JSON.parse(localStorage.getItem('op')) || data.operator;

	return (
		<div
			className='themes-header home-container'
			className={operator && operator.operator_name === "busnorte" ? "themes-header themes-header-new home-container" : "themes-header home-container"}
			style={{
				background: 
					showBackground &&
						operator &&
						operator.color_codes &&
						operator.color_codes.header_color
						? operator.color_codes.header_color
						: '',
			}}
		>
			<div onClick={() => menuController.open()}>
				{operator && operator.operator_name === "busnorte" ? (
					<img
						className='qr-back-arrow'
						src={
							getIcons('menuDot')
								? getIcons('menuDot')
								: '../../assets/busnorte/Home/menu-dot.svg'
						}
						alt=''
					/>) : operator && operator.operator_name === "chilebus" ?
					(<img
						className='qr-back-arrow'
						src={
							getIcons('menuDot')
								? getIcons('menuDot')
								: '../../assets/chilebus/dots.svg'
						}
						alt=''
					/>) : operator && operator.operator_name === "jeldres" || operator.operator_name === "santarosa"  ?
						(<img
							className='qr-back-arrow'
							src={
								getIcons('menuDot')
									? getIcons('menuDot')
									: '../../assets/jeldres/dots.svg'
							}
							alt=''
						/>) : operator && operator.operator_name === "biaggini" ?
						(<img
							className='qr-back-arrow'
							src={
								getIcons('menuDot')
									? getIcons('menuDot')
									: '../../assets/biaggini/blackdots.svg'
							}
							alt=''
						/>) : (<img
							className='qr-back-arrow'
							src={
								getIcons('menuDot')
									? getIcons('menuDot')
									: '../../assets/carpool/menu-dot.png'
							}
							alt=''
						/>)
				}
			</div>
			<div className='new-header_logo' className={operator && operator.operator_name === "tarapaca" ? "new-header_logo2" : "new-header_logo"}>

				{/* <a href='/'>
          <img
           src={operator && operator.theme_type === 11 && window.location.pathname === "/contact" || window.location.pathname === "/terms-conditions" ? getIcons("HeaderLogoYellow"): operator && operator.header_logo}
          />
										
										
        </a> */}
				<a href="/">
					<img
						// style={{
						// 	width: '235px',
						// 	// height: 50,
						// 	height: 'auto',
						// }}
						src={
							operator && operator.header_logo
							// getIcons('headerLogo')
						}
						alt=""
						id="logo"
					/>
				</a>
			</div>
			<div>&nbsp;</div>
		</div>
	);
};

export default connectData()(Header);
