import aeroquintaIcons from "./aeroquintaIcons";
import andimarIcons from "./andimarIcons";
import arzolaIcons from "./arzolaIcons";
import bahiaazulIcons from "./bahiaazulIcons";
import biagginiIcons from "./biagginiIcons";
import busesBarrialIcons from "./busesbarriaIcons";
import busesjmIcons from "./busesjmIcons";
import busnorteIcons from "./busnorteIcons";
import centralTourIcons from "./centralTourIcons";
import centropuertoIcons from "./centropuertoIcons";
import chilebusIcons from "./chilebusIcons";
import combarbalaIcons from "./combarbalaIcons";
import cormarbusIcons from "./cormarbusIcons";
import erbucIcons from "./erbucIcons";
import expresoSanatCruzIcons from "./expresosantacruzIcons";
import expressonorteIcons from "./expressonorteIcons";
import fernandezIcons from "./fernandezIcons";
import ivergramaIcons from "./ivergramaIcons";
import jeldresIcons from "./jeldresIcons";
import jetsurIcons from "./jetsurIcons";
import kennybusIcons from "./kennybusIcons";
import lineaazulIcons from "./lineaazulIcons";
// import lineaazulIcons from "./lineaazulIcons";
import liquineIcons from "./liquineIcons";
import pullmanSanAndreasIcons from "./pullmanSanAndreasIcons";
import pullmandelsurIcons from "./pullmandelsurIcons";
import sanantonioIcons from "./sanantonioIcons";
import santajuanaIcons from "./santajuanaIcons";
import santamariaIcons from "./santamariaIcons";
import santarosaIcons from "./santarosaIcons";
import saopauloIcons from "./saopauloIcons";
import serenamarIcons from "./serenamarIcons";
import tacohaIcons from "./tacohaIcons";
import tarapacaIcons from "./tarapacaIcons";
import tepualIcons from "./tepualIcons";
import thaebusIcons from "./thaebusIcons";
import travelturIcons from "./travelturIcons";
import trrassIcons from "./trrassIcons";
import viaturIcons from "./viaturIcons";

const icons = {
	andimar: andimarIcons,
	tepual: tepualIcons,
	expressonorte: expressonorteIcons,
	bahiaazul: bahiaazulIcons,
	ivergrama: ivergramaIcons,
	kennybus: kennybusIcons,
	santamaria: santamariaIcons,
	biaggini: biagginiIcons,
	erbuc: erbucIcons,
	saopaulo: saopauloIcons,
	chilebus: chilebusIcons,
	santajuana: santajuanaIcons,
	pullmandelsur: pullmandelsurIcons,
	viatur: viaturIcons,
	cormarbus: cormarbusIcons,
	serenamar: serenamarIcons,
	fernandez: fernandezIcons,
	pullmansanandres: pullmanSanAndreasIcons,
	jetsur: jetsurIcons,
	busesjm: busesjmIcons,
	barriaghisoni: busesBarrialIcons,
	combarbala: combarbalaIcons,
	expresosantacruz: expresoSanatCruzIcons,
	trrass: trrassIcons,
	sanantonio: sanantonioIcons,
	traveltur: travelturIcons,
	thaebus: thaebusIcons,
	centraltour: centralTourIcons,
	liquine: liquineIcons,
	tacoha: tacohaIcons,
	tarapaca: tarapacaIcons,
	centropuerto: centropuertoIcons,
	busnorte: busnorteIcons,
	aeroquinta: aeroquintaIcons,
	lineaazul: lineaazulIcons,
	arzola: arzolaIcons ,
	jeldres: jeldresIcons,
	santarosa: santarosaIcons,
};

const getIcons = (type) => {
	const operator = JSON.parse(localStorage.getItem("op"));

	const name = operator && operator.operator_name ? operator.operator_name : "";
	const data = icons[name] ? icons[name] : [];

	// console.log(name, data, '------------')
	if (type === "kuposLogo") {
		// return footerKuposLogo;
	} else {
		const showIcon = data.find((val) => val.name === type);
		// console.log({ type, showIcon });
		return showIcon && showIcon.icon ? showIcon.icon : "";
	}
};

export default getIcons;
