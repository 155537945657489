// import React from "react";
// import { IonContent, IonPage } from "@ionic/react";

// import { connectData } from "../../redux";
// import HeroSection from "../common/HeroSection/HeroSection";
// import getIcons from "../functions/getIcons/getIcons";
// import ThemeTwoFooter from "./common/ThemeTwoFooter";
// import ThemeTwoContactForm from "./common/ThemeTwoContactForm";
// import Header from "../common/header/Header";
// import KuposHeader from "../../components/KuposHeader";

// const ThemeTwoContactUsScreen = (props) => {
// const operator = JSON.parse(localStorage.getItem('op'));
//   return (
//     <IonPage>
//       <IonContent>
//         <Header />
//         <KuposHeader
//         boldTitle={"Contacto"}
//         handleHomeSubmit={() => props.history.push("/")}
//         handleBackSubmit={() => props.history.goBack()}
//         />
//         <HeroSection
//           height={"30vh"}
//           heroImage={getIcons("Banner")}
//           title={"Contacto"}
//           description={"Home / Contacto"}

//         />

//         <ThemeTwoContactForm history={props.history} />

//         <ThemeTwoFooter operator={props.data.operator} />
//       </IonContent>
//     </IonPage>
//   );
// };

// export default connectData()(ThemeTwoContactUsScreen);





import React from "react";
import { IonContent, IonPage } from "@ionic/react";

import { connectData } from "../../redux";
import HeroSection from "../common/HeroSection/HeroSection";
import getIcons from "../functions/getIcons/getIcons";
import ThemeTwoFooter from "./common/ThemeTwoFooter";
import ThemeTwoContactForm from "./common/ThemeTwoContactForm";
import Header from "../common/header/Header";
import KuposHeader from "../../components/KuposHeader";

const ThemeTwoContactUsScreen = (props) => {
  const operator = JSON.parse(localStorage.getItem('op'));

  return (
    <IonPage>
      <IonContent>
        {!(operator && operator.operator_name === "tarapaca") && <Header />}
        {operator && operator.operator_name === "tarapaca" && (
          <KuposHeader
            // boldTitle={"Contacto"} 
            handleHomeSubmit={() => props.history.push("/")}
            handleBackSubmit={() => props.history.goBack()}
            logo={operator && operator.header_logo}
          />
        )}
        <HeroSection
          height={"30vh"}
          heroImage={getIcons("Banner")}
          title={"Contacto"}
          description={"Home / Contacto"}
        />

        <ThemeTwoContactForm history={props.history} />

        <ThemeTwoFooter operator={props.data.operator} />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(ThemeTwoContactUsScreen);