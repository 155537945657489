
import React from "react";
import { IonContent, IonPage } from "@ionic/react";

import { connectData } from "../../redux";
import HeroSection from "../common/HeroSection/HeroSection";
import getIcons from "../functions/getIcons/getIcons";
import ThemeTwoFooter from "./common/ThemeTwoFooter";
import Header from "../common/header/Header";
import KuposHeader from "../../components/KuposHeader";
import ThemeTwoTitle from "./common/ThemeTwoTitle";
import ContactFormService from "../common/contact/ContactFormService";

const SantarosaServiceScreen = (props) => {
  const operator = JSON.parse(localStorage.getItem('op'));

  return (
    <IonPage>
      <IonContent>
         <Header />
        
        <HeroSection
          height={"30vh"}
          heroImage={getIcons("Banner")}
          title={"Servicios especiales"}
          description={"Home / Servicios especiales"}
        />

        <ThemeTwoTitle  title='Servicios especiales' />

        <div className="service-form">
            <ContactFormService operator={props.data.operator} props={props} />
          </div>

        <ThemeTwoFooter operator={props.data.operator} />
      </IonContent>
    </IonPage>
  );
};

export default connectData()(SantarosaServiceScreen);
